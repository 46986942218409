@font-face {
font-family: '__deacon_d95d33';
src: url(/_next/static/media/98b0f8d9e71d25bd-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
}@font-face {font-family: '__deacon_Fallback_d95d33';src: local("Arial");ascent-override: 97.69%;descent-override: 21.69%;line-gap-override: 0.00%;size-adjust: 100.52%
}.__className_d95d33 {font-family: '__deacon_d95d33', '__deacon_Fallback_d95d33';font-weight: 900
}

@font-face {
font-family: '__graphik_e57a3f';
src: url(/_next/static/media/921492e6011e7011-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: '__graphik_e57a3f';
src: url(/_next/static/media/6dcebf4fc82fcd14-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}@font-face {font-family: '__graphik_Fallback_e57a3f';src: local("Arial");ascent-override: 92.25%;descent-override: 18.34%;line-gap-override: 0.00%;size-adjust: 106.34%
}.__className_e57a3f {font-family: '__graphik_e57a3f', '__graphik_Fallback_e57a3f'
}

